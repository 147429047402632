var Site = {

    init : function() {
        this.domReady();
        this.onResize();
        this.onScroll();
    },

    domReady : function() {
        $(document).ready(function() {

            $().iniLazyloading();
            $().buttonScrolling();
            $().initMenu();

        });
    },

    onResize : function() {

    },

    onScroll : function() {

    }

};

// Init
Site.init();