/**
 * This Software is the property of degramming Fynn Korsen and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   lazyloading
 * @author     Fynn Korsen <info@degramming.de>
 * @license    http://www.degramming.de Commercial
 * @link       http://www.degramming.de
 */
(function ($) {
    'use strict';

    $.fn.iniLazyloading  = function() {
        window.lazySizesConfig = window.lazySizesConfig || {};
        document.addEventListener('lazybeforeunveil', function(e){
            $(e.target).parent('.e-placeholder').removeClass('is--spin');
        });
    };
})(jQuery);