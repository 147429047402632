/**
 * This Software is the property of degramming Fynn Korsen and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   menu
 * @author     Fynn Korsen <info@degramming.de>
 * @license    http://www.degramming.de Commercial
 * @link       http://www.degramming.de
 */
(function ($) {
    'use strict';

    $.fn.initMenu  = function() {
        var _button = $(".e-hamburger");
        var _menu = $(".c-menu");

        _button.on("click", function(){
            _menu.toggleClass("is--active");

            if (_button.hasClass("is--active")) {
                window.setTimeout(function(){
                    _button.removeClass("is--active");
                }, 200);
            }else {
                _button.addClass("is--active");
            }
        });
    };
})(jQuery);