/**
 * This Software is the property of degramming Fynn Korsen and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   buttonScrolling
 * @author     Fynn Korsen <info@degramming.de>
 * @license    http://www.degramming.de Commercial
 * @link       http://www.degramming.de
 */
(function ($) {
    'use strict';

    $.fn.buttonScrolling  = function() {
        var scrollDuration = 1000;

        $("#hero_button").on("click", function(){
            $('html, body').animate({
                scrollTop: $("#home_intro").offset().top
            }, scrollDuration);
        });
    };
})(jQuery);